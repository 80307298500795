import React, { Component } from 'react';
import { firebaseApp } from '../Firebase';
import firebase from 'firebase/app';
import 'firebase/database';
import { Container, Row, Col, Table, Form } from 'react-bootstrap';
import BillingDetailsForm from './BillingDetailsForm';
import PaymentInput from './Stripe/PaymentInput';
import swal from 'sweetalert';
import { doesExist, sendNoReplyEmail, getSection, getShippingFee } from '../../common_code';
import { adminTeamDL, linkBase } from '../../Constants';

const db = firebase.database(firebaseApp);

class Checkout extends Component {
    state = {
        form: {
            billing_details: {
                first_name: "",
                last_name: "",
                organization_name: "",
                phone: "",
                email: "",
                address: "",
                address_2: "",
                city: "",
                state: "",
                zip_code: ""
            },
            shipping_details: {
                is_local_delivery_required: false,
                is_shipping_required: false,
                is_shipping_address_different: false,
                first_name: "",
                last_name: "",
                organization_name: "",
                phone: "",
                email: "",
                address: "",
                address_2: "",
                city: "",
                state: "",
                zip_code: ""
            },
        },
        cart: {},
        order_details: {
            subtotal: 0.0,
            total: 0.0,
            shipping_fee: 0,
            order_ts: "",
            is_order_reviewed: false,
            is_order_fulfilled: false
        },
        payment_details: {
            payment_method: {},
            payment_intent: ""
        },
        doItemsRequireShipping: false,
        order_id: "",
    }

    constructor(props) {
        super(props);

        this.handleChangeBillingDetails = this.handleChangeBillingDetails.bind(this);
    }


    componentWillMount = async () => {
        await this.setState({
            cart: this.props.cart
        });

        this.getTotal();

        this.doItemsRequireShipping();
    }

    handleChangeBillingDetails = ({ target }) => {
        let form = { ...this.state.form };
        form.billing_details[target.name] = target.value;
        this.setState({ form });
    }

    handleChangeShippingDetails = ({ target }) => {
        let form = { ...this.state.form };

        if (target.name === "is_shipping_address_different" || target.name === "is_local_delivery_required" || target.name === "is_shipping_required") {
            form.shipping_details[target.name] = !form.shipping_details[target.name]

            if (target.name === "is_local_delivery_required") {
                form.shipping_details["is_shipping_required"] = false;
            }
            else if (target.name === "is_shipping_required") {
                form.shipping_details["is_local_delivery_required"] = false;
            }
        }
        else {
            form.shipping_details[target.name] = target.value;
        }

        this.setState({ form });
        this.updateShippingFee();
    }

    updateShippingFee = () => {
        let shippingFee = getShippingFee(this.state.order_details.subtotal, this.state.form.shipping_details.is_shipping_required)
        let orderDetailsCopy = this.state.order_details;
        orderDetailsCopy.shipping_fee = parseFloat(shippingFee).toFixed(2);
        this.setState({ order_details: orderDetailsCopy });

        this.getTotal();
    }

    getSubtotal = () => {
        let subtotal = 0.0;
        if (this.state.cart.length > 0) {
            this.state.cart.map((item, i) => {
                let price = parseFloat(item.price);
                let itemTotalPrice = price * parseInt(item.quantity);
                subtotal += itemTotalPrice;
            });
        }
        subtotal = subtotal.toFixed(2);

        let order_details = this.state.order_details;
        order_details.subtotal = parseFloat(subtotal).toFixed(2);
        this.setState({ order_details });
    }

    getTotal = () => {
        this.getSubtotal();

        let total = 0.0;

        let subtotal = parseFloat(this.state.order_details.subtotal).toFixed(2);
        let shipping_fee = parseFloat(this.state.order_details.shipping_fee).toFixed(2);

        total = parseFloat(subtotal) + parseFloat(shipping_fee);

        let order_details = this.state.order_details;
        order_details.total = parseFloat(total).toFixed(2);
        this.setState({ order_details });
    }

    saveOrder = async (clientSecret, paymentMethod) => {
        let data = this.state;
        data.order_details.order_ts = new Date().getTime();

        let paymentIntentArray = clientSecret.split('_secret_');
        let paymentIntent = paymentIntentArray[0];

        data.payment_details.payment_intent = paymentIntent;
        data.payment_details.payment_method = paymentMethod;

        //order_id has to be in state, but don't want to save to db; order_id doesn't exist yet
        delete data.order_id;

        let order_id = await db.ref('store_orders').push(data).catch((err) => {
            console.error(err.message);
            swal("Well, this is awkward...", "We're sorry. We had trouble saving your order. Contact helpdesk@tolmguate.org if the error continues.", "error");
            return;
        });

        this.setState({
            order_id
        });
    }

    sendAdminEmail = async () => {

        // let billing_details = this.state.form.billing_details;
        let order_id = this.state.order_id;

        let recipientEmail = adminTeamDL;
        let subject = `New Online Store Order!`;

        let htmlContent = `
        <h1>New Order Has Been Submitted</h1><br/>
        <p>Order: ${order_id}</p>
        <pre>${JSON.stringify(this.state, null, 2)}</pre>
        `

        await sendNoReplyEmail(recipientEmail, subject, htmlContent);
    }

    sendCustomerEmail = async () => {

        // let billing_details = this.state.form.billing_details;
        let order_id = this.state.order_id.toString().split('/');
        order_id = order_id[order_id.length - 1];

        //let recipientEmail = 'helpdesk@tolmguate.org'; 
        let recipientEmail = this.state.form.billing_details.email;
        let subject = `Your TOLM order!`;

        let itemsList = "";
        if (this.state.cart.length > 0) {
            this.state.cart.map((item, i) => {

                let link = linkBase;
                link += doesExist(item.color) ?
                    encodeURI(`${getSection(item)}/item/${item.name.replace(/\s+/g, '-').toLowerCase()}/item-number/${item.itemNumber}/?color=${item.color}`)
                    : encodeURI(`${getSection(item)}/item/${item.name.replace(/\s+/g, '-').toLowerCase()}/item-number/${item.itemNumber}`);

                let itemName = item.name;
                if (doesExist(item.color) && item.color.length > 0 && doesExist(item.size) && item.size.length > 0)
                    itemName += ` (${item.color.trim()}, ${item.size.trim()})`;
                else if (doesExist(item.color))
                    itemName += ` (${item.color})`;
                else if (doesExist(item.color))
                    itemName += ` (${item.size})`;

                itemsList += `<tr>
                        <td style="padding-bottom: 1em; align="left"><a href="${link}">${itemName}</a></td>
                        <td style="padding-bottom: 1em; align="left">$${parseFloat(item.price).toFixed(2)}</td>
                        <td style="padding-bottom: 1em; align="left">${item.quantity}</td>
                    </tr>`
            })
        }

        // let itemsTable = `
        //     <table width="600" style="border:1px solid #333;">
        //         <tr>
        //             <th style="padding-bottom: 1em; text-align: left;"><h3>Item</h3></th>
        //             <th style="padding-bottom: 1em; text-align: left;"><h3>Price</h3></th>
        //             <th style="padding-bottom: 1em; text-align: left;"><h3>Quantity</h3></th>
        //         </tr>
        //         ${itemsList}
        //     </table>
        // `;

        let itemsTable = `
                <html>
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <meta http-equiv="X-UA-Compatible" content="ie-edge">
                <title></title>
                <style>
                    body {
                        margin: 0px !important;
                        padding: 0px !important;
                    }	
                    @media only screen and (max-width:480px) {
                        .wrapper {
                            width: 100% !important;
                        }
                </style>
            </head>
            <body>
                <!--[if (gte mso 9)|(IE)]>
                <table align="center" border="0" cellspacing="0" cellpadding="0" width="100%" bgcolor="#2b3452" style="background-color:#2b3452;">
                <tr>
                <td align="center" valign="top">
                <![endif]-->
                <table align="center" cellpadding="0" cellspacing="0" width="100%" bgcolor="#2b3452">
                    <tr>
                        <td align="center" valign="top">
                        <!--[if (gte mso 9)|(IE)]>
                        <table align="center" border="0" cellspacing="0" cellpadding="0" width="600">
                        <tr>
                        <td align="center" valign="top" width="600">
                        <![endif]-->
                            <table border="0" cellpadding="0" cellspacing="0" width="600" class="wrapper">
                                <tbody>
                                    <tr>
                                        <th align="left">Item</th>
                                        <th align="left">Price</th>
                                        <th align="left">Quantity</th>
                                    </tr>
                                    <tr>
                                        <!--<td align="center" valign="top">
                                            SOME CONTENT!
                                        </td>-->
                                        <td>Student Desk</td>
                                        <td>$50.00</td>
                                        <td>1</td>
                                    </tr>
                                    <tr>
                                        <td style="padding-bottom: 1em; align="left">Adidas Golf Men's Climalite Pique Polo (Blue, X-Large)</td>
                                        <td style="padding-bottom: 1em; align="left">$53.54</td>
                                        <td style="padding-bottom: 1em; align="left">1</td>
                                    </tr>
                                </tbody>
                            </table>
                        <!--[if (gte mso 9)|(IE)]>
                        </td>
                        </tr>
                        </table>
                        <![endif]-->  
                        </td>
                    </tr>
                </table>
                <!--[if (gte mso 9)|(IE)]>
                </td>
                </tr>
                </table>
                <![endif]-->
            </body>
        </html>
`;

        let shippingParagraph = "";
        if (this.state.form.shipping_details.is_local_delivery_required) {
            shippingParagraph = `You have selected local delivery, stating you live within 30 miles of Granite City. 
            One of our TOLM staff will drop off your order within 7-10 business days.`
        }
        else if (this.state.form.shipping_details.is_shipping_required) {
            shippingParagraph = `You have selected shipping via USPS.  Your order should arrive within 7-10 business days.`
        }

        let htmlContent = `
        <h1>Thank you for your order with TOLM!</h1><br/>
        <p>Thank you for your order with TOLM! We truly appreciate your support.</p>
        <p>${shippingParagraph}</p>
        <p><strong>Order Reference ID:</strong> ${order_id}</p>
        <br/>
        <h2>Your Order</h2>
        ${itemsTable}
        
        `
        await sendNoReplyEmail(recipientEmail, subject, htmlContent);
    }

    onSuccessfulCheckout = () => {
        //this.props.clearCart();
        window.location.href = '/checkout-success'; //call clearCart() in /order-confirmation page
        //TODO: Create and redirect to post-checkout page thanking for order
    }

    doItemsRequireShipping = () => {
        let items = this.state.cart;

        let result = items.filter(item => item.section === "STORE");

        let doItemsRequireShipping = undefined;

        if (result.length > 0)
            doItemsRequireShipping = true;
        else
            doItemsRequireShipping = false;

        this.setState({ doItemsRequireShipping });
    }

    render() {
        let orderItems = undefined;
        if (this.state.cart.length > 0) {
            let alphabetizedItems = this.state.cart.sort((a, b) => (a.name > b.name) ? 1 : -1);
            orderItems = alphabetizedItems.map((item, i) => {

                let link = doesExist(item.color) ?
                    encodeURI(`${getSection(item)}/item/${item.name.replace(/\s+/g, '-').toLowerCase()}/item-number/${item.itemNumber}/?color=${item.color}`)
                    : encodeURI(`${getSection(item)}/item/${item.name.replace(/\s+/g, '-').toLowerCase()}/item-number/${item.itemNumber}`);

                let itemName = item.name;
                if (doesExist(item.color) && item.color.length > 0 && doesExist(item.size) && item.size.length > 0)
                    itemName += ` (${item.color.trim()}, ${item.size.trim()})`;
                else if (doesExist(item.color))
                    itemName += ` (${item.color})`;
                else if (doesExist(item.color))
                    itemName += ` (${item.size})`;

                return (
                    <tr key={i}>
                        <td><a href={link}>{itemName}</a></td>
                        <td>{item.quantity}</td>
                        <td>${parseFloat(item.price).toFixed(2)}</td>
                    </tr>
                );
            })
        }


        let feesAndTotals =
            <div className='text-right'>
                <p style={{ fontWeight: 500 }}>Subtotal:  ${parseFloat(this.state.order_details.subtotal).toFixed(2)}</p>
                {this.state.order_details.shipping_fee > 0 &&
                    <p style={{ fontWeight: 500 }}>Shipping Cost: ${parseFloat(this.state.order_details.shipping_fee).toFixed(2)}</p>
                }
                {this.state.form.shipping_details.is_local_delivery_required &&
                    <p style={{ fontWeight: 500 }}>Local Delivery: ${parseFloat(this.state.order_details.shipping_fee).toFixed(2)}</p>
                }
                <p style={{ fontWeight: 500 }}>Total: ${parseFloat(this.state.order_details.total).toFixed(2)}</p>
            </div>


        return (
            <Container className="header-margin">
                <Form className="bg-light p-5">

                    <Row>
                        <BillingDetailsForm
                            handleChangeBillingDetails={this.handleChangeBillingDetails}
                            stateFormSection={this.state.form.billing_details}
                            sizeXS="12"
                            sizeSM="6"
                            title="Billing Details"
                        >
                        </BillingDetailsForm>

                        {this.state.doItemsRequireShipping &&
                            <Col xs={12} sm={6}>

                                <div>
                                    <Row className="ml-1 mb-4">
                                        <h4>Shipping Address</h4>
                                    </Row>

                                    <Row>
                                        <Col xs={1}>
                                            <input type="checkbox" name="is_shipping_address_different" onChange={this.handleChangeShippingDetails} checked={this.state.form.shipping_details.is_shipping_address_different}></input>
                                        </Col>
                                        <Col className="text-left">
                                            <label>Ship to a different address?</label>
                                        </Col>
                                    </Row>


                                    {this.state.form.shipping_details.is_shipping_address_different &&
                                        <Row>
                                            <BillingDetailsForm className="mb-5"
                                                handleChangeBillingDetails={this.handleChangeShippingDetails}
                                                stateFormSection={this.state.form.shipping_details}
                                                sizeXS="12"
                                                sizeSM="12"
                                                title=""
                                            >
                                            </BillingDetailsForm>
                                        </Row>
                                    }
                                </div>

                            </Col>
                        }
                    </Row>

                    {this.state.doItemsRequireShipping &&
                        <Row>
                            <Col xs={12} sm={6} className="mb-5">
                                <Row className="ml-1 mb-4 mobile-top-margin">
                                    <h4>Delivery Method</h4>
                                </Row>
                                <Row>
                                    <Col xs={1}>
                                        <input type="checkbox" name="is_local_delivery_required" onChange={this.handleChangeShippingDetails} checked={this.state.form.shipping_details.is_local_delivery_required}></input>
                                    </Col>
                                    <Col className="text-left">
                                        <label>Local delivery (we'll hand-deliver your order for free if you live within 30 miles of Granite City, IL)</label>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={1}>
                                        <input type="checkbox" name="is_shipping_required" onChange={this.handleChangeShippingDetails} checked={this.state.form.shipping_details.is_shipping_required}></input>
                                    </Col>
                                    <Col className="text-left">
                                        <label>Ship my order via USPS (additional charges apply)</label>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    }

                    <Row className="ml-1 mb-3">
                        <h4>Your Order</h4>
                    </Row>

                    <Row className="mb-5">
                        {/* Table with items from cart */}
                        <Table striped bordered responsive>
                            <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderItems}
                            </tbody>
                        </Table>
                        <Col>
                            {feesAndTotals}
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={6}>
                            <PaymentInput
                                checkoutState={this.state}
                                onSuccessfulCheckout={this.onSuccessfulCheckout}
                                total={this.state.order_details.total}
                                saveOrder={this.saveOrder}
                                sendAdminEmail={this.sendAdminEmail}
                                sendCustomerEmail={this.sendCustomerEmail}
                            />
                        </Col>
                    </Row>

                </Form>
            </Container >
        );
    }
}

export default Checkout;
