import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { statesList } from '../../common_code';

const BillingDetailsForm = (props) => {
    let statesOptions = statesList.map((state, i) => {
        return (
            <option key={i} value={state.abbreviation}>{state.name}</option>
        )
    });

    return (
        <Col xs={props.sizeXS} sm={props.sizeSM} className="mb-5">
            <Row className="ml-1 mb-3">
                <h4>{props.title}</h4>
            </Row>

            <Row className="mb-4">
                <Col xs={12} sm={6}>
                    <input className="form-control" name="first_name" onChange={props.handleChangeBillingDetails} value={props.stateFormSection.first_name} placeholder="First name"></input>
                </Col>
                <Col xs={12} sm={6} className="mobile-top-margin">
                    <input className="form-control" name="last_name" onChange={props.handleChangeBillingDetails} value={props.stateFormSection.last_name} placeholder="Last name"></input>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col>
                    <input className="form-control" name="organization_name" onChange={props.handleChangeBillingDetails} value={props.stateFormSection.organization_name} placeholder="Group/Organization Name (optional)"></input>
                </Col>
            </Row>

            <Row className="mb-4">

                <Col xs={12} sm={6}>
                    <input className="form-control" name="phone" onChange={props.handleChangeBillingDetails} value={props.stateFormSection.phone} placeholder="Phone"></input>
                </Col>
                <Col xs={12} sm={6} className="mobile-top-margin">
                    <input className="form-control" name="email" onChange={props.handleChangeBillingDetails} value={props.stateFormSection.email} placeholder="Email"></input>
                </Col>
            </Row>

            <hr />

            <Row className="mb-4">
                <Col>
                    <input className="form-control" name="address" onChange={props.handleChangeBillingDetails} value={props.stateFormSection.address} placeholder="Address"></input>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col>
                    <input className="form-control" name="address_2" onChange={props.handleChangeBillingDetails} value={props.stateFormSection.address_2} placeholder="Address 2 (optional)"></input>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col>
                    <input className="form-control" name="city" onChange={props.handleChangeBillingDetails} value={props.stateFormSection.city} placeholder="City"></input>
                </Col>
            </Row>
            <Row>
                <Col>
                    <select className="form-control" name="state" onChange={props.handleChangeBillingDetails} value={props.stateFormSection.state}>
                        <option value="">State</option>
                        {statesOptions}
                    </select>
                </Col>
                <Col>
                    <input className="form-control" name="zip_code" onChange={props.handleChangeBillingDetails} value={props.stateFormSection.zip_code} placeholder="Zip code"></input>
                </Col>
            </Row>
        </Col>
    )
}

export default BillingDetailsForm;