import React, { Component } from 'react';
import { firebaseApp } from '../Firebase';
import firebase from 'firebase/app';
import 'firebase/database';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { doesExist } from '../../common_code';
import swal from 'sweetalert';
import '../../assets/css/swal-error.css';
import { getImage } from '../../common_code';
import queryString from 'query-string';
import BreadCrumbs from './BreadCrumbs';


const db = firebase.database(firebaseApp);


class ItemBig extends Component {

    state = {
        itemNumber: '',
        item: {}
    }

    constructor(props) {
        super(props);
    }

    componentWillMount = async () => {
        await this.getSection();

        document.title = 'Store-Tapestry of Love Ministries';

        this.getItem();

        // this.setState({
        //     cart: this.state.cart
        // });


    }

    // shouldComponentUpdate(nextProps) {
    //     return nextProps.cart !== this.state.cart;
    // }

    // componentDidUpdate(props) {
    //     this.setState({ cart: props.cart })
    // }

    getSection = () => {
        let path = this.props.location.pathname.split('/');
        let sectionIndex = path[1].toString().trim().toUpperCase();

        let dbSection = undefined;
        let section = undefined;
        let sectionLink = undefined;

        if (sectionIndex === "STORE") {
            dbSection = "store_inventory";
            section = "Store";
            sectionLink = '/store';
        }
        else if (sectionIndex === "VIRTUAL-CATALOG") {
            dbSection = "virtual_catalog_inventory";
            section = "Virtual Catalog";
            sectionLink = '/virtual-catalog';
        }
        this.setState({ dbSection, section, sectionLink });
    }

    onChangeQuantity = (e) => {
        let itemCopy = this.state.item;
        itemCopy.quantity = parseInt(e.target.value);
        this.setState({ item: itemCopy });
    }

    onChangeSize = (e) => {
        let itemCopy = this.state.item;
        itemCopy.size = e.target.value;
        this.setState({ item: itemCopy });
    }

    onChangeColor = (e) => {
        let itemCopy = this.state.item;
        itemCopy.color = e.target.value;
        this.setState({ item: itemCopy });


        if (itemCopy.color.length > 0) {
            let currentUrlParams = new URLSearchParams(window.location.search);
            currentUrlParams.set('color', this.state.item.color);
            this.props.history.push(window.location.pathname + "?" + currentUrlParams.toString());
        } else {
            this.props.history.push(window.location.pathname);
        }
    }

    getItem() {
        let itemNumber = this.props.match.params.item_number;

        if (doesExist(itemNumber) && itemNumber.length > 0) {
            this.setState({ itemNumber: itemNumber });

            let dbSection = this.state.dbSection;

            db.ref(`${this.state.dbSection}/` + itemNumber).on('value', (snapshot) => {
                let item = snapshot.val();
                if (item) {
                    item.quantity = 1;
                    item.itemNumber = itemNumber;

                    let values = queryString.parse(this.props.location.search);
                    let color = values.color;
                    if (doesExist(color)) {
                        let itemCopy = this.state.item;
                        itemCopy.color = color;
                        this.setState({ item: itemCopy });
                    } else if (doesExist(item.colors_available) && item.colors_available.length > 0) {
                        let colorsOrdered = item.colors_available.split(',').sort();
                        let defaultColor = colorsOrdered[0];
                        item.color = defaultColor;
                    }
                    this.setState({
                        item
                    });

                    document.title = `${this.state.item.name}-Tapestry of Love Ministries`
                } else {
                    swal("Well, this is awkward...", "Could not retrieve the item. Item number doesn't exist.", "error").then(() => {
                        if (dbSection !== undefined) {
                            this.props.history.push(`/${dbSection}`)
                        } else {
                            this.props.history.push('/');
                        }

                    })
                }
            });
        }
        else {
            swal("Well, this is awkward...", "Could not find an item number", "error");
        }
    }

    addToCart = (item) => {
        //validate quantity, size and color are picked (if applicable)
        if (doesExist(item.quantity) && (!doesExist(item.quantity) || item.quantity.length === 0)) {
            swal("Quantity is not selected", "Could not add to cart", "error");
        }
        else if (doesExist(item.sizes_available) && (!doesExist(item.size) || item.size.length === 0)) {
            swal("Size is not selected", "Could not add to cart", "error");
        }
        else if (doesExist(item.colors_available) && (!doesExist(item.color) || item.color.length === 0)) {
            swal("Color is not selected", "Could not add to cart", "error")
        }
        else {
            this.props.addItemToCart(item);

            //Go to cart
            window.location.href = "/cart";
        }
    }

    getButton = () => {

        let outOfStockButtonStyle = {
            width: "100%",
            backgroundColor: "gray",
            color: "white",
            borderRadius: ".3rem",
            fontWeight: "400",
            whiteSpace: "nowrap",
            display: "inline-block",
            fontSize: "1.25rem",
            padding: ".2rem 1rem"
        }

        let name = this.state.item.name;

        if (name === "Child Sponsorship")
            return <a href="https://tolmguate.org/child-sponsorship.html" target="_blank"><button className='navy-button'>Learn More</button></a>
        else if (name === "Teacher Sponsorship")
            return <a href="https://tolmguate.org/teacher-sponsorship.html" target="_blank"><button className='navy-button'>Learn More</button></a>
        else if (name === "Missionary Sponsorship")
            return <a href="https://tolmguate.org/missionary-sponsorship.html" target="_blank"><button className='navy-button'>Learn More</button></a>
        else if ((this.state.item.in_stock_quantity > 0) || (this.state.item.in_stock_quantity === -999))
            return <button className='navy-button' onClick={() => this.addToCart(this.state.item)}>Add to Cart</button>
        else
            return <button style={outOfStockButtonStyle} disabled>Out of Stock</button>
    }


    render() {
        let item = this.state.item;

        let sizes = [];
        if (doesExist(item.sizes_available) && item.sizes_available.length > 0) {
            sizes = item.sizes_available.split(',').map((o, index) => {
                return <option key={index} value={o.trim()}>{o.trim()}</option>
            });
        }

        let colors = [];
        if (doesExist(item.colors_available) && item.colors_available.length > 0) {
            let colorsOrdered = item.colors_available.split(',').sort();
            colors = colorsOrdered.map((o, index) => {
                return <option key={index} value={o.trim()}>{o.trim()}</option>
            });
        }

        let imageStyle = {
            maxWidth: "100%",
            maxHeight: "450px",
            border: "8px solid #002060",
            marginTop: "5px"
        }


        let image = getImage(item.images, item.color);

        return (
            <div style={{ backgroundColor: '#e6e6e6' }} className="pb-5">

                <BreadCrumbs item_name={item.name} section={this.state.section} sectionLink={this.state.sectionLink} />

                <Container className="mt-5">

                    <Row className="justify-content-between">
                        <Col xs={12} sm={12} md={7} className="p3-5">
                            <img alt={item.name + " product image"} src={image} style={imageStyle}></img>

                        </Col>
                        <Col xs={12} sm={12} md={5} className="text-left">
                            <div>
                                <h2 style={{ color: "#002060" }}>{item.name}</h2>
                                <h5 style={{ display: "inline" }}>Item Number: </h5><p style={{ display: "inline" }}>{item.itemNumber}</p>
                                <hr />
                            </div>
                            <strong><h4>${parseFloat(item.price).toFixed(2)}</h4></strong>

                            <hr />
                            <p style={{ lineHeight: 2 }}>{item.description}</p>

                            {doesExist(item.tag) &&
                                <p style={{ lineHeight: 2, fontStyle: 'italic' }} className="mt-4">{item.tag}</p>
                            }

                            <hr />
                            <Row>
                                <Col lg={4}>
                                    <label><h5>Quantity</h5></label>
                                    <select className="form-control" onChange={this.onChangeQuantity.bind(this)}>
                                        <option defaultValue value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </select>
                                </Col>


                                {sizes.length > 0 &&
                                    <Col lg={4} className="mobile-top-margin">
                                        <label><h5>Size</h5></label>
                                        <select className="form-control" onChange={this.onChangeSize.bind(this)}>
                                            <option value="">Select</option>
                                            {sizes}
                                        </select>
                                    </Col>
                                }



                                {colors.length > 0 &&
                                    <Col lg={4} className="mobile-top-margin">
                                        <label><h5>Color</h5></label>
                                        <select className="form-control" onChange={this.onChangeColor.bind(this)} value={this.state.item.color}>
                                            {colors}
                                        </select>
                                    </Col>
                                }
                            </Row>

                            <hr />

                            <Row className="mt-3">
                                {/* Add to cart button */}
                                <Col>
                                    {this.getButton()}
                                </Col>
                            </Row>



                        </Col>
                    </Row>
                </Container >
            </div>
        );
    }
}

export default ItemBig;