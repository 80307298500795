import React, { Component } from 'react';
import { firebaseApp } from '../Firebase';
import firebase from 'firebase/app';
import 'firebase/database';
import { snapshotToArray } from '../../common_code';
import { Row, Container, Col } from 'react-bootstrap';
import ItemCard from './ItemCard';

const db = firebase.database(firebaseApp);

class Store extends Component {

    state = {
        items: []
    }

    constructor(props) {
        super(props);
        //this.getItems();
    }

    componentDidMount() {
        this.getItems();
    }


    getItems = () => {

        db.ref(this.props.db_path).on('value', (snapshot) => {
            //Clear array of items anytime Firebase .on callback is fired

            let items = [];
            snapshotToArray(snapshot).forEach((o) => {
                items.push(o);
            });
            this.setState({
                items: items
            });
        });
    }

    render() {

        let items = this.state.items;
        let alphabetizedItems = items.sort((a, b) => (a.name > b.name) ? 1 : -1);

        return (
            <div>
                <Container className="header-margin">
                    <Row>
                        {
                            alphabetizedItems.map((o, index) => {
                                if (o.is_active === true
                                    && (
                                        (o.in_stock_quantity <= 0 && o.is_restockable === true)
                                        || (o.in_stock_quantity > 0)
                                    )
                                ) {

                                    return (
                                        <ItemCard key={index}
                                            {...this.props}
                                            name={o.name}
                                            description={o.description}
                                            images={o.images}
                                            price={o.price}
                                            in_stock_quantity={o.in_stock_quantity}
                                            item_number={o.key}
                                            section={o.section}
                                            cart={this.state.cart}
                                            addItemToCart={this.props.addItemToCart}
                                        >
                                        </ItemCard>
                                    )
                                }
                            })
                        }

                        {this.state.items.length === 0 &&
                            <Col className="mt-5">
                                <h3>No items to display at this time!</h3>
                            </Col>
                        }
                    </Row>
                </Container >
            </div>
        );
    }


}
export default Store;