import React from 'react';

const Error = () => {

    return (
        <section id="content">
            <h1>Page Not Found</h1>
            <h3>The page you are trying to access does not exist</h3>
        </section>
    );
}

export default Error;