import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import successImage2 from '../../assets/images/CheckoutSuccess/child-sponsorship.PNG';

class CheckoutSuccess extends Component {

    constructor(props) {
        super(props);
    }


    componentWillMount = () => {
        if (this.props.cart.length !== 0) {
            this.props.clearCart();
        }
    }

    render() {

        return (
            <Container className="p-5">
                <Row>
                    <Col>
                        <h1>Thank you for your order!</h1>
                    </Col>
                </Row>
                <Row className="mt-3 justify-content-center">
                    <Col xs={3}>
                        <button className="navy-button" onClick={() => window.location.href = "/"}>Back to Home</button>
                    </Col>
                </Row>

                <Row className="mt-5">
                    <Col>
                        <img src={successImage2} style={{ maxWidth: "100%", maxHeight: "500px" }}></img>
                    </Col>

                </Row>
            </Container>
        )
    }
}

export default CheckoutSuccess;