import swal from 'sweetalert';
import validator from 'email-validator';
import { postcodeValidator } from 'postcode-validator';
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();


export const validateCheckoutForm = (checkoutState) => {

    let isFormValid = true;

    if (!validateBillingDetails(checkoutState)) { return false }


    if (checkoutState.doItemsRequireShipping) {
        if (!validateShippingCheckboxes(checkoutState)) { return false };

        if (checkoutState.form.shipping_details.is_shipping_address_different) {
            if (!validateShippingDetails(checkoutState)) { return false };
        }
    }

    return isFormValid;
}

const validateShippingCheckboxes = (checkoutState) => {

    const form_section = 'Delivery Method'

    let isShippingChosen = false;
    if (checkoutState.form.shipping_details.is_local_delivery_required
        || checkoutState.form.shipping_details.is_shipping_required) {
        isShippingChosen = true;
    } else {
        swal(`${form_section} | Please select a delivery method`, "Please complete the form and submit again", "error");
    }

    return isShippingChosen;
}

const validateBillingDetails = (checkoutState) => {
    // BILLING DETAILS
    const form_section = "Billing Details";

    //First name
    if (checkoutState.form.billing_details.first_name.length === 0) {
        swal(`${form_section} | First name is empty`, "Please complete the form and submit again", "error");
        return false;
    }

    // Last name
    if (checkoutState.form.billing_details.last_name.length === 0) {
        swal(`${form_section} | Last name is empty`, "Please complete the form and submit again", "error");
        return false;
    }

    // Phone
    if (checkoutState.form.billing_details.phone.length === 0) {
        swal(`${form_section} | Phone is empty`, "Please complete the form and submit again", "error");
        return false;
    }

    try {
        const number = phoneUtil.parseAndKeepRawInput(checkoutState.form.billing_details.phone, 'US');
        if (!phoneUtil.isValidNumber(number)) {
            swal(`${form_section} | Phone is invalid`, "Please complete the form and submit again", "error");
            return false;
        }
    } catch (e) {
        swal(`${form_section} | Phone is invalid`, "Please complete the form and submit again", "error");
        return false;
    }

    // Email
    if (checkoutState.form.billing_details.email.length === 0) {
        swal(`${form_section} | Email is empty`, "Please complete the form and submit again", "error");
        return false;
    }

    if (!validator.validate(checkoutState.form.billing_details.email)) {
        swal(`${form_section} | Email is invalid`, "Please complete the form and submit again", "error");
        return false;
    }

    // Address
    if (checkoutState.form.billing_details.address.length === 0) {
        swal(`${form_section} | Address is empty`, "Please complete the form and submit again", "error");
        return false;
    }

    //City
    if (checkoutState.form.billing_details.city.length === 0) {
        swal(`${form_section} | City is empty`, "Please complete the form and submit again", "error");
        return false;
    }

    //State
    if (checkoutState.form.billing_details.state.length === 0) {
        swal(`${form_section} | State is empty`, "Please complete the form and submit again", "error");
        return false;
    }

    //Zip
    if (checkoutState.form.billing_details.zip_code.length === 0) {
        swal(`${form_section} | Zip code is empty`, "Please complete the form and submit again", "error");
        return false;
    }

    if (!postcodeValidator(checkoutState.form.billing_details.zip_code, 'US')) {
        swal(`${form_section} | Zip code is invalid`, "Please complete the form and submit again", "error");
        return false;
    }

    return true;
}


const validateShippingDetails = (checkoutState) => {
    // SHIPPING DETAILS
    const form_section = "Shipping Details";

    //First name
    if (checkoutState.form.shipping_details.first_name.length === 0) {
        swal(`${form_section} | First name is empty`, "Please complete the form and submit again", "error");
        return false;
    }

    // Last name
    if (checkoutState.form.shipping_details.last_name.length === 0) {
        swal(`${form_section} | Last name is empty`, "Please complete the form and submit again", "error");
        return false;
    }

    // Phone
    if (checkoutState.form.shipping_details.phone.length === 0) {
        swal(`${form_section} | Phone is empty`, "Please complete the form and submit again", "error");
        return false;
    }

    try {
        const number = phoneUtil.parseAndKeepRawInput(checkoutState.form.shipping_details.phone, 'US');
        if (!phoneUtil.isValidNumber(number)) {
            swal(`${form_section} | Phone is invalid`, "Please complete the form and submit again", "error");
            return false;
        }
    } catch (e) {
        swal(`${form_section} | Phone is invalid`, "Please complete the form and submit again", "error");
        return false;
    }

    // Email
    if (checkoutState.form.shipping_details.email.length === 0) {
        swal(`${form_section} | Email is empty`, "Please complete the form and submit again", "error");
        return false;
    }

    if (!validator.validate(checkoutState.form.shipping_details.email)) {
        swal(`${form_section} | Email is invalid`, "Please complete the form and submit again", "error");
        return false;
    }

    // Address
    if (checkoutState.form.shipping_details.address.length === 0) {
        swal(`${form_section} | Address is empty`, "Please complete the form and submit again", "error");
        return false;
    }

    //City
    if (checkoutState.form.shipping_details.city.length === 0) {
        swal(`${form_section} | City is empty`, "Please complete the form and submit again", "error");
        return false;
    }

    //State
    if (checkoutState.form.shipping_details.state.length === 0) {
        swal(`${form_section} | State is empty`, "Please complete the form and submit again", "error");
        return false;
    }

    //Zip
    if (checkoutState.form.shipping_details.zip_code.length === 0) {
        swal(`${form_section} | Zip code is empty`, "Please complete the form and submit again", "error");
        return false;
    }

    if (!postcodeValidator(checkoutState.form.shipping_details.zip_code, 'US')) {
        swal(`${form_section} | Zip code is invalid`, "Please complete the form and submit again", "error");
        return false;
    }

    return true;
}