import React, { Component } from 'react';
import { Card, Button, Col } from 'react-bootstrap';
import { getImage } from '../../common_code';
import '../../assets/css/item-card.css';

// const ItemCard = (props) => {
class ItemCard extends Component {

    state = {
        items: []
    }

    constructor(props) {
        super(props);
        //this.getItems();
    }

    componentDidMount() {

        // this.setState({
        //     cart: this.props.cart
        // });
    }

    render() {
        let link = "";
        if (this.props.section === "STORE") {
            link = encodeURI(`store/item/${this.props.name.replace(/\s+/g, '-').toLowerCase()}/item-number/${this.props.item_number}`);
        }
        else if (this.props.section === "VIRTUAL_CATALOG") {
            link = encodeURI(`virtual-catalog/item/${this.props.name.replace(/\s+/g, '-').toLowerCase()}/item-number/${this.props.item_number}`);
        }

        let image = getImage(this.props.images);

        return (
            <Col xs={6} sm={6} md={4}>
                <div
                    // style={{ width: '20rem', cursor: 'pointer', marginRight: '20px' }}
                    style={{ width: '100%', cursor: 'pointer' }}
                    className="mobile-top-margin"
                    onClick={() => window.location.href = link}
                >
                    <Card.Img variant="top" src={image} className="item-image" />

                    <Card.Body>
                        <Card.Title><h4>{this.props.name}</h4></Card.Title>
                        <h5>${this.props.price.toFixed(2)}</h5>
                        {getButton(this.props.in_stock_quantity, this.props.item_number, this.props.name)}
                    </Card.Body>
                </div>
            </Col>
        )
    }
}

const getButton = (in_stock_quantity, item_number, name) => {
    //let link = encodeURI(`store/item/${name}/${item_number}`);

    // if (in_stock_quantity > 0) {
    //     return (
    //         <Link to={link} >
    //             <Button variant="primary" onClick={() => window.location.href = link}>Add to Cart</Button>
    //         </Link >
    //     )
    // }
    if ((in_stock_quantity <= 0) && (in_stock_quantity !== -999)) {
        return (
            <Button variant="dark" disabled>Out of Stock</Button>
            // <Alert variant="dark">Out of Stock</Alert>
        )
    }

}
export default ItemCard;