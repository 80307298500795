import React from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import styled from "@emotion/styled";
import axios from "axios";
import { Row } from 'react-bootstrap';
import CheckoutError from "./CheckoutError";
import { chargeStoreFunctionURL } from "../../../Constants";
import { validateCheckoutForm } from '../CheckoutFormValidation';

const CardElementContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  background: white;
  border: 1px solid #ced4da;
  border-radius: 5px;

  & .StripeElement {
    width: 100%;
    padding: 15px;
  }
`;


const CheckoutForm = (props) => {

    const [isProcessing, setProcessingTo] = React.useState(false);
    const [checkoutError, setCheckoutError] = React.useState();

    const stripe = useStripe();
    const elements = useElements();

    const handleCardDetailsChange = ev => {
        ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
    };

    const handleFormSubmit = async ev => {
        ev.preventDefault();

        if (!validateCheckoutForm(props.checkoutState))
            return;

        const billingDetails = {
            name: `${props.checkoutState.form.billing_details.first_name} ${props.checkoutState.form.billing_details.last_name}`,
            email: props.checkoutState.form.billing_details.email,
            address: {
                city: props.checkoutState.form.billing_details.city,
                line1: props.checkoutState.form.billing_details.address,
                state: props.checkoutState.form.billing_details.state,
                postal_code: props.checkoutState.form.billing_details.zip_code
            }
        };

        setProcessingTo(true);

        const cardElement = elements.getElement("card");

        try {
            const { data: clientSecret } = await axios.post(chargeStoreFunctionURL, {
                //amount: price * 100
                // amount: parseInt((parseFloat(props.total).toFixed(2)) * 100)
                cart: props.checkoutState.cart,
                isShippingRequired: props.checkoutState.form.shipping_details.is_shipping_required
            });

            const paymentMethodReq = await stripe.createPaymentMethod({
                type: "card",
                card: cardElement,
                billing_details: billingDetails
            });

            if (paymentMethodReq.error) {
                setCheckoutError(paymentMethodReq.error.message);
                setProcessingTo(false);
                return;
            }

            const { error } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: paymentMethodReq.paymentMethod.id
            });

            if (error) {
                setCheckoutError(error.message);
                setProcessingTo(false);
                return;
            }
            else {
                await props.saveOrder(clientSecret, paymentMethodReq);
                await props.sendAdminEmail();
                //await props.sendCustomerEmail();
                props.onSuccessfulCheckout();
            }
        } catch (err) {
            setCheckoutError(err.message);
        }
    };

    const cardElementOptions = {
        style: {
            base: {
                iconColor: '#2cb1e2',
                fontSize: '16px'

            }
        },
        hidePostalCode: true
    }

    return (
        <span>
            <Row className="ml-1 mb-3">
                <h4>Payment Details</h4>
            </Row>
            <span className="mb-3">
                <CardElementContainer>
                    <CardElement
                        options={cardElementOptions}
                        onChange={handleCardDetailsChange}
                    />
                </CardElementContainer>
            </span>
            {checkoutError && <CheckoutError>{checkoutError}</CheckoutError>}

            {/* <Button type="submit" style={{ width: "100%" }} onClick={handleFormSubmit} disabled={isProcessing || !stripe} className="mt-4">
                {isProcessing ? "Processing..." : `Pay $${parseFloat(props.total).toFixed(2)}`}
            </Button> */}

            <button type="submit" onClick={handleFormSubmit} disabled={isProcessing || !stripe} className="mt-4 navy-button">
                {isProcessing ? "Processing..." : `Pay $${parseFloat(props.total).toFixed(2)}`}
            </button>
        </span>
    );
};

export default CheckoutForm;