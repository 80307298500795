import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { getImage, doesExist } from '../../common_code';
import { Link } from 'react-router-dom';
import { getSection } from '../../common_code';

class Cart extends Component {

    state = {}

    constructor(props) {
        super(props);
    }

    componentWillMount = () => {

        this.setState({
            cart: this.props.cart
        });
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.cart !== this.state.cart;
    }

    componentDidUpdate(props) {
        this.setState({ cart: props.cart })
    }

    changeQuantity = (newQuantityRef, cartItem) => {

        let { itemNumber } = cartItem;
        let { color } = cartItem;
        let { size } = cartItem;

        let itemCopy = this.state.cart.find(item => (item.itemNumber === itemNumber) && (item.color === color) && (item.size === size));

        itemCopy.quantity = parseInt(newQuantityRef.current.value);

        this.props.updateItemInCart(itemCopy);
    }

    removeItem = (item) => {
        this.props.removeItemFromCart(item)
    }

    // calculateItemTotal = (item) => {
    //     return (parseInt(item.quantity) * parseFloat(item.price)).toFixed(2);
    // }

    getSubtotal = () => {
        let subtotal = 0.0;
        this.state.cart.map((item, i) => {
            let price = parseFloat(item.price);
            let itemTotalPrice = price * parseInt(item.quantity);
            subtotal += itemTotalPrice;
        });
        return subtotal.toFixed(2);
    }



    render() {

        let quantityRefs = [];

        let alphabetizedItems = this.state.cart.sort((a, b) => (a.name > b.name) ? 1 : -1);

        let items = alphabetizedItems.map((item, i) => {

            const newQuantityRef = React.createRef();
            quantityRefs.push(newQuantityRef);

            let link = doesExist(item.color) ?
                encodeURI(`${getSection(item)}/item/${item.name.replace(/\s+/g, '-').toLowerCase()}/item-number/${item.itemNumber}/?color=${item.color}`)
                : encodeURI(`${getSection(item)}/item/${item.name.replace(/\s+/g, '-').toLowerCase()}/item-number/${item.itemNumber}`);

            let image = getImage(item.images, item.color);

            return <Row key={i} className="justify-content-center bg-light p-4" style={{ borderBottom: "1px solid #7a7a7a" }}>
                <Col xs={6} sm={2}>
                    {/* <div onClick={() => window.location.href = link} className="img" style={{ backgroundImage: `url(${image})`, cursor: 'pointer' }}></div> */}
                    <img src={image} style={{ maxWidth: "100%", cursor: 'pointer', border: '2px solid #002060' }} onClick={() => window.location.href = link} alt={item.name + " product image"}></img>
                </Col>
                <Col sm={6}>
                    <a href={link}><h5>{item.name}</h5></a>
                    {item.size &&
                        <p><strong>Size: </strong>{item.size}</p>
                    }
                    {item.color &&
                        <p><strong>Color: </strong> {item.color}</p>
                    }
                </Col>

                <Col sm={2}>
                    <select className="form-control" style={{ maxWidth: "100px", margin: "auto" }} defaultValue={item.quantity}
                        ref={newQuantityRef} onChange={() => this.changeQuantity(newQuantityRef, item)}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>
                    <p className="small" style={{ cursor: "pointer", color: "blue" }} onClick={() => this.removeItem(item)}>Delete</p>
                </Col>
                <Col sm={2}>
                    ${parseFloat(item.price).toFixed(2)}
                </Col>
                {/* <Col xs={2}>
                    ${this.calculateItemTotal(item)}
                </Col> */}
            </Row>
        });


        return (
            <Container className="mb-5 header-margin" >
                <Row style={{ borderBottom: "1px solid grey" }} className="mb-5 pl-3">
                    <h2>My Cart</h2>
                </Row>

                {doesExist(this.state.cart) && this.state.cart.length > 0 ?
                    <React.Fragment>
                        {items}
                        <Row className="mt-5 justify-content-end">
                            <Col xs={12} sm={6} md={4} className="text-right">
                                <h5>Subtotal: ${this.getSubtotal()}</h5>
                                <p>Shipping (if applicable) calculated at checkout</p>
                                <Link to="/checkout"><button className="navy-button">Go to checkout</button></Link>
                            </Col>
                        </Row>
                    </React.Fragment>

                    :
                    <div>
                        <h4 className="mb-4">No items in cart!</h4>
                        <Link to="/"><Button size="lg" variant="outline-info">Back to shopping</Button></Link>
                    </div>
                }
            </Container>
        );
    }
}

export default Cart;