import React, { Component } from 'react';
import navy_blue_logo from "../assets/images/navy-blue-logo.png";
import { Navbar, Nav, Container } from 'react-bootstrap';
import '../assets/css/cart.css';
import { doesExist } from '../common_code';

class Header extends Component {

    state = {}

    constructor(props) {
        super(props);
    }

    componentWillMount = () => {

        this.setState({
            cart: this.props.cart
        });
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.cart !== this.state.cart;
    }

    componentDidUpdate(props) {
        this.setState({ cart: props.cart })
    }

    getNumberOfItemsInCart = () => {
        let totalQuantity = 0;

        this.state.cart.map((item, i) => {
            totalQuantity += parseInt(item.quantity);
        });

        return totalQuantity;
    }

    render() {
        let numberItems = this.getNumberOfItemsInCart() || 0;

        return (
            <React.Fragment>
                <Container className="mb-0">
                    <Navbar expand="lg">
                        <Navbar.Brand href="https://www.tolmguate.org"><img alt="TOLM Logo" src={navy_blue_logo} className="logo"></img></Navbar.Brand>
                        <Navbar.Brand href="https://www.tolmguate.org" className="navbar-brand title-in-navbar">TOLM</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto">
                                <Nav.Link href="/">Home</Nav.Link>
                                {/* <Nav.Link href="/silent-auction">Silent Auction</Nav.Link> */}
                                <Nav.Link href="/store">Store</Nav.Link>
                                <Nav.Link href="/virtual-catalog">Virtual Catalog</Nav.Link>
                                {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                            </NavDropdown> */}
                            </Nav>
                        </Navbar.Collapse>

                        <div id="ex4">
                            <span className="p1 fa-stack fa-2x has-badge" data-count={numberItems}
                                onClick={() => window.location.href = "/cart"} style={{ cursor: 'pointer' }}>
                                <i className="p3 fa fa-shopping-cart fa-stack-1x xfa-inverse" data-count="4b"></i>
                            </span>
                        </div>
                    </Navbar>
                    {/* <h1>Shop TOLM</h1> */}
                </Container>
                <div style={{ backgroundColor: '#00274c' }} className="text-light pt-3 pb-3">
                    <h1>Shop TOLM</h1>
                </div>
            </React.Fragment>

        );
    }



}
export default Header;