import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../assets/css/bread-crumbs.css';

class BreadCrumbs extends Component {


    render() {
        return (
            <div className="text-left bread-crumbs bg-dark text-light">
                <h5 className="ml-2 mb-0 mt-0"><Link to="/" className="link">Home</Link> / <Link to={this.props.sectionLink} className="link">{this.props.section}</Link> / {this.props.item_name}</h5>
            </div>
        )
    }
}

export default BreadCrumbs;