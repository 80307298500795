import React from 'react';
import { Container, Row } from 'react-bootstrap';
import '../assets/css/footer.css';

const Footer = () => {
    return (
        <Container fluid>
            <Row className="text-center footer mt-5">
                <div className="col-md-4 mb-2 mb-md-0">
                    <h4 style={{ fontWeight: '500' }}>TOLM</h4>
                    <hr className="light" />
                    <p><strong>Email:</strong> <a href="mailto:admin@tolmguate.org">admin@tolmguate.org</a></p>
                    <p><strong>Phone:</strong> 314-474-0065</p>
                    <p>3035 Nameoki Dr.</p>
                    <p>Granite City, IL 62040</p>
                </div>
                <div className="col-md-4 mb-2 mb-md-0">
                    <h4 style={{ fontWeight: '500' }} > Sponsorship</h4>
                    <hr className="light" />
                    <p><a href="child-sponsorship.html">Child Sponsorship</a></p>
                    <p><a href="teacher-sponsorship.html">Teacher Sponsorship</a></p>
                    <p><a href="missionary-sponsorship.html">Missionary Sponsorship</a></p>
                </div>
                <div className="col-md-4 social padding">
                    <h4 style={{ fontWeight: '500' }}>Social Media</h4>
                    <hr className="light" />
                    <a href="https://www.facebook.com/tolmguatemala/"><i className="fa fa-facebook"></i></a>
                    <a href="https://www.instagram.com/tolmguate/"><i className="fa fa-instagram"></i></a>
                </div>
                <div className="col-12 mt-2">
                    <hr className="light" />
                    <p>&copy; 2020 Tapestry of Love Ministries</p>
                    <small>
                        <p>All brand logos, photos, and images are property of TOLM and may not be used without
                expressed written permission.</p>
                    </small>
                    <small>
                        <p>Tapestry of Love Ministries is a 501(c)3 nonprofit organization. Donations are tax deductible
                as allowed by law.</p>
                    </small>
                </div>
            </Row>
        </Container>
    );

}

export default Footer;