import { environment } from './environment_config';

let stripePublicKeyDev = 'pk_test_SgsFovvH1wp0PLbRLiXUEBrx';
let stripePublicKeyProd = 'pk_live_0S2R6mH2dSpDWVCTRyH4k6J3';
export const stripePublicKey = environment === "prod" ? stripePublicKeyProd : stripePublicKeyDev;

let linkBaseDev = 'localhost:3000/';
let linkBaseProd = 'store.tolmguate.org/';
export const linkBase = environment === "prod" ? linkBaseProd : linkBaseDev;

let adminTeamDLDev = 'helpdesk@tolmguate.org';
let adminTeamDLProd = 'helpdesk@tolmguate.org;admin@tolmguate.org;executive_director@tolmguate.org;adminguate@tolmguate.org';
export const adminTeamDL = environment === "prod" ? adminTeamDLProd : adminTeamDLDev;

const urlBase = `https://us-central1-tolm-${environment}.cloudfunctions.net`;

export const chargeStoreFunctionURL = `${urlBase}/chargeStore`;
export const noReplyEmailFunctionURL = `${urlBase}/noReplyEmail`;