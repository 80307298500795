import React from 'react';
import './App.css';
import Router from './Router.js';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { stripePublicKey } from './Constants';

const stripePromise = loadStripe(stripePublicKey);
//TIP: don't call loadStripe within render method of component (don't want to load more than required)

function App() {
  return (
    <div className="App">

      <Elements stripe={stripePromise}>
        <Router />
      </Elements>

    </div>
  );
}

export default App;
