import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import shopTOLM from '../../assets/images/Home/shop_tolm.png';
import schoolSupplies from '../../assets/images/Home/school_supplies.png';
import tolmTumbler from '../../assets/images/Home/tolm_tumbler.png';


class Home extends Component {
    render() {
        let imgStyle = {
            maxWidth: '100%'
        }
        return (
            <Container className="header-margin">
                <Row>
                    <Col className="p-1">
                        <img src={shopTOLM} style={imgStyle} alt="Shop TOLM Banner"></img>
                    </Col>
                </Row>
                <Row >
                    <Col xs={12} sm={6} className="m-0 p-1">
                        <Link to="/virtual-catalog" style={{ textDecoration: 'none' }}>
                            <img src={schoolSupplies} style={imgStyle} alt="School Supplies"></img>

                            <div className="mt-1 pt-1 pb-1" style={{ backgroundColor: '#41cfe7' }}>
                                <h3 className='text-white' style={{ fontWeight: '300' }}>Enter Virtual Catalog</h3>
                            </div>
                        </Link>
                    </Col>

                    <Col xs={12} sm={6} className="m-0 p-1">
                        <Link to="/store" style={{ textDecoration: 'none' }}>
                            <img src={tolmTumbler} style={imgStyle} alt="TOLM Tumbler"></img>

                            <div className="mt-1 pt-1 pb-1" style={{ backgroundColor: 'navy' }}>
                                <h3 className='text-white' style={{ fontWeight: '300' }}>Enter TOLM Store</h3>
                            </div>
                        </Link>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Home;