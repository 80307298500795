import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Header from './components/Header';
import Store from './components/Store/Store';
import Error from './components/Error';
import ItemBig from './components/Store/ItemBig';
import Cart from './components/Store/Cart';
import { TitleComponent } from './components/TitleComponent';
import Checkout from './components/Store/Checkout';
import CheckoutSuccess from './components/Store/CheckoutSuccess';
import Home from './components/Store/Home';
import Footer from './components/Footer';

class Router extends Component {

    state = {
        cart: []
    }

    componentWillMount() {
        this.getCart();
    }

    getCart = () => {

        let localStorageCartString = localStorage.getItem("cart") || "[]";
        let localStorageCart = JSON.parse(localStorageCartString);

        let cartCopy = [...localStorageCart];

        // let item1 = {
        //     price: 49.99,
        //     name: "Google Home",
        //     ID: Math.floor(Math.random() * 100)
        // };

        // cartCopy.push(item1);

        this.setState({
            cart: cartCopy
        });

        let stringCart = JSON.stringify(cartCopy);
        localStorage.setItem("cart", stringCart)
    }

    addItemToCart = (item) => {
        let cart = this.state.cart;
        let cartCopy = [...cart];

        //assuming we have an ID field in our item
        let { itemNumber } = item;
        let { color } = item;
        let { size } = item;


        //look for item in cart array
        let existingItem = cartCopy.find(item => (item.itemNumber === itemNumber) && (item.color === color) && (item.size === size));

        //if item already exists
        if (existingItem) {
            let existingItemQuantity = parseInt(existingItem.quantity);
            existingItem.quantity = existingItemQuantity + parseInt(item.quantity) //update item
            //existingItem.quantity += item.quantity;
        } else { //if item doesn't exist, simply add it
            cartCopy.push(item)
        }

        //update app state
        this.setState({ cart: cartCopy })

        //make cart a string and store in local space
        let stringCart = JSON.stringify(cartCopy);
        localStorage.setItem("cart", stringCart)
    }

    updateItemInCart = (item) => {
        let cart = this.state.cart;
        let cartCopy = [...cart];

        //assuming we have an ID field in our item
        let { itemNumber } = item;
        let { color } = item;
        let { size } = item;

        //find if item exists, just in case
        let existentItem = cartCopy.find(item => (item.itemNumber === itemNumber) && (item.color === color) && (item.size === size));

        //if it doesnt exist simply return
        if (!existentItem) return

        //continue and update quantity
        existentItem.quantity = item.quantity;

        //validate result
        if (existentItem.quantity <= 0) {
            //remove item  by filtering it from cart array
            cartCopy = cartCopy.filter(item => item.itemNumber !== itemNumber)
        }

        //again, update state and localState
        this.setState({ cart: cartCopy })

        let cartString = JSON.stringify(cartCopy);
        localStorage.setItem('cart', cartString);
    }

    removeItemFromCart = (item) => {
        let cart = this.state.cart;
        let cartCopy = [...cart];

        //assuming we have an ID field in our item
        let { itemNumber } = item;

        cartCopy = cartCopy.filter(item => item.itemNumber !== itemNumber);

        //update state and local
        this.setState({ cart: cartCopy })

        let cartString = JSON.stringify(cartCopy)
        localStorage.setItem('cart', cartString)
    }

    clearCart = () => {
        //update state and local
        let cart = []
        this.setState({ cart: cart })

        let cartString = JSON.stringify(cart);
        localStorage.setItem('cart', cartString)
    }


    withTitle = ({ component: Component, title }) => {
        return class Title extends Component {
            render() {
                return (
                    <React.Fragment>
                        <TitleComponent title={title} />
                        <Component {...this.props} />
                    </React.Fragment>
                );
            }
        };
    };


    render() {

        const HomeComponent = this.withTitle({ component: Home, title: 'Home-Tapestry of Love Ministries' });
        const StoreComponent = this.withTitle({ component: Store, title: 'Store-Tapestry of Love Ministries' });
        const VirtualCatalogComponent = this.withTitle({ component: Store, title: 'Virtual Catalog-Tapestry of Love Ministries' });
        const CartComponent = this.withTitle({ component: Cart, title: 'Cart-Tapestry of Love Ministries' });
        const CheckoutComponent = this.withTitle({ component: Checkout, title: 'Checkout-Tapestry of Love Ministries' });
        const CheckoutSuccessComponent = this.withTitle({ component: CheckoutSuccess, title: 'Checkout Success-Tapestry of Love Ministries' });

        return (
            <BrowserRouter>

                <Header cart={this.state.cart} />

                <Switch>
                    <Route exact path="/" component={HomeComponent} />

                    <Route exact path="/store" render={(props) => (
                        <StoreComponent
                            {...props}
                            cart={this.state.cart}
                            addItemToCart={this.addItemToCart}
                            db_path="store_inventory"
                        />
                    )} />

                    <Route exact path="/virtual-catalog" render={(props) => (
                        <VirtualCatalogComponent
                            {...props}
                            cart={this.state.cart}
                            addItemToCart={this.addItemToCart}
                            db_path="virtual_catalog_inventory"
                        />
                    )} />

                    {/* Path could be like /store/item/... or /virtual-catalog/item/... */}
                    <Route path="*/item/:item_name/item-number/:item_number" render={(props) => (
                        <ItemBig
                            {...props}
                            cart={this.state.cart}
                            addItemToCart={this.addItemToCart}
                        />
                    )} />

                    <Route exact path="/cart" render={(props) => (
                        <CartComponent
                            {...props}
                            cart={this.state.cart}
                            addItemToCart={this.addItemToCart}
                            updateItemInCart={this.updateItemInCart}
                            removeItemFromCart={this.removeItemFromCart}
                        />
                    )} />

                    <Route exact path="/checkout" render={(props) => (
                        <CheckoutComponent
                            {...props}
                            cart={this.state.cart}
                            clearCart={this.clearCart}
                        />
                    )} />

                    {/* <Route exact path="/StripeTest" component={StripeTest}></Route> */}
                    <Route exact path="/checkout-success" render={(props) => (
                        <CheckoutSuccessComponent
                            {...props}
                            cart={this.state.cart}
                            clearCart={this.clearCart}
                        />
                    )} />

                    <Route component={Error} />
                </Switch>

                <Footer />

            </BrowserRouter >


        );
    }
}

export default Router;