import firebase from 'firebase/app';

import { environment } from '../environment_config.js';

const dev_config = {
    apiKey: "AIzaSyDQKKklHsbFnI0_WofBDOl1WfabpmhxQI0",
    authDomain: "tolm-dev.firebaseapp.com",
    databaseURL: "https://tolm-dev.firebaseio.com",
    projectId: "tolm-dev",
    storageBucket: "tolm-dev.appspot.com",
    messagingSenderId: "327698814382"
};

const prod_config = {
    apiKey: "AIzaSyA94weWCN7LVhtLUDwU7c9CmYzxhzt1u8Y",
    authDomain: "tolm-prod.firebaseapp.com",
    databaseURL: "https://tolm-prod.firebaseio.com",
    projectId: "tolm-prod",
    storageBucket: "tolm-prod.appspot.com",
    messagingSenderId: "888437122019"
};



export const firebaseApp = firebase.initializeApp((environment === "prod") ? prod_config : dev_config);
//export const firebaseApp = firebase.initializeApp(dev_config);